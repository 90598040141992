<template>
	<!-- <div>
        Tipo inversionista {{this.$props.tipoInversionista}}
    </div> -->
    
        <v-card class="text-xs-center" style="max-width: 598px;">
            <v-container>
                <v-layout row wrap>
                    <v-flex xs12 
                        class="text-xs-center rc-view"
                        style="padding: 0 35px;"
                    >
                        <div>
                            <v-img :src='$props.tipoInversionista === "Agresivo" ? contenido[2].image :
                                         $props.tipoInversionista === "Moderado" ? contenido[1].image :
                                         $props.tipoInversionista === "Conservador" ? contenido[0].image : false' 
                                    width="300px"
                                    style="margin:auto" />
                        </div>
                        <div class="my-4">
                            <h2 v-html='$props.tipoInversionista === "Agresivo" ? contenido[2].title :
                                        $props.tipoInversionista === "Moderado" ? contenido[1].title :
                                        $props.tipoInversionista === "Conservador" ? contenido[0].title : false' /> 
                        </div>
                        <div class="my-4">
                            <p style="font-weight: 500; font-size: 22px; text-align: center;"
                                v-html='$props.tipoInversionista === "Agresivo" ? contenido[2].text :
                                        $props.tipoInversionista === "Moderado" ? contenido[1].text :
                                        $props.tipoInversionista === "Conservador" ? contenido[0].text : false' />
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			tipoInversionista : String
        },
        data() {
            return {
                //inversion: this.$props.tipoInversionista,
                contenido: [{
                        image: '/images/perfil-inv/tipos/conservador.png',
                        title: 'CONSERVADOR',
                        text : 'Eres cuidadoso con tus inversiones, pero estas dispuesto a correr un pequeño riesgo para obtener ganancias.' 
                    },{
                        image: '/images/perfil-inv/tipos/moderado.png',
                        title: 'MODERADO',
                        text : 'Estas dispuesto a arriesgar un poco tu inversión para obtener mayores ganancias.' 
                    },{
                        image: '/images/perfil-inv/tipos/agresivo.png',
                        title: 'AGRESIVO',
                        text : 'Estas dispuesto a asumir un alto riesgo en tus inversiones para obtener mayores ganancias.' 
                    }
                ]
            };
        }
	})
	export default class TipoInversionista extends Vue {}
</script>

<style lang="scss" scoped>
    
</style>
